<div class="mat-dialog-header">
  <div class="mat-dialog-title">{{title}}</div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <mat-form-field class="full-width-input">
    <mat-select placeholder="Select an option" [formControl]="fileTypeSelector">
      <mat-option value="student-behavior">Student, Behavior</mat-option>
      <mat-option value="student">Student</mat-option>
      <mat-option value="student-points">Student, Points</mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container
    *ngIf="!isLoading && behaviorCodeTypeOptions?.length && operatingMode && operatingMode != 'student-behavior'">
    <mat-form-field class="full-width-input">
      <mat-select
        placeholder="Select an option"
        [formControl]="behaviorCodeTypeIdControl">
        <mat-option
          *ngFor="let option of behaviorCodeTypeOptions"
          [value]="option.id">
          {{option.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <ng-container
    *ngIf="!isLoading && behaviorCodeOptions?.length && operatingMode && operatingMode != 'student-behavior'">
    <mat-form-field class="full-width-input">
      <mat-select
        placeholder="Select an option"
        [formControl]="behaviorCodeIdControl">
        <mat-option
          *ngFor="let option of behaviorCodeOptions"
          [value]="option.id">
          {{option.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <ng-container
    *ngIf="!isLoading && hasPrivilege(PrivilegeEnum.CREATE_BEHAVIOR_NOTES) && operatingMode && (behaviorCodeOptions?.length || operatingMode == 'student-behavior')">
    <!-- Note field -->
    <mat-form-field class="text-area">
      <mat-label>Notes</mat-label>
      <textarea
        class="text-area"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="15"
        [formControl]="notesControl"
        matInput
        placeholder="Notes"></textarea>
    </mat-form-field>
  </ng-container>

  <app-file-upload
    *ngIf="!isLoading && ((behaviorCodeIdControl.value && operatingMode) || operatingMode == 'student-behavior')"
    [allowedExtensions]="['.csv']"
    [maxMBFileSize]="10"
    (fileChanged)="handleFileChanged($event)">
  </app-file-upload>

  <div class="modal-action-row">
    <button
      type="button"
      mat-button
      class="modal-cancel-button"
      (click)="dialogRef.close();">
      Cancel
    </button>
    <button
      [disabled]="!fileContent || isLoading || (operatingMode && operatingMode != 'student-behavior' && !behaviorCodeIdControl.value)"
      type="submit"
      mat-button
      class="modal-submit-button"
      (click)="submit()">
      Track
    </button>
  </div>
</div>
