<div class="table-container mat-elevation-z2">
  <div class="table-filters-top">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="roles-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <ng-container matColumnDef="{{ columns.INCIDENT_NUMBER }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ columns.INCIDENT_NUMBER }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.incidentNumber }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.DATE }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ columns.DATE }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ DateUtil.convertTimestampToDisplayDate(row.date) }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.STATUS }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ columns.STATUS }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.status }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.DESCRIPTION }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ columns.DESCRIPTION }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.description }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.TYPE }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ columns.TYPE }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.incidentType }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.REPORTER }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ columns.REPORTER }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.reporter }}
        </mat-cell>
      </ng-container>
      <ng-container class="action-row" matColumnDef="{{ columns.ACTION }}">
        <mat-header-cell class="action-column-header" *matHeaderCellDef>
          {{ columns.ACTION }}
        </mat-header-cell>
        <mat-cell class="action-column-cell" *matCellDef="let row">
          <button
            mat-flat-button
            class="action-button icon-button"
            [routerLink]="'/incident/' + row.id.toString()"
            options-id="EDIT_INCIDENT"
            [option]="row.id">
            <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
          </button>
          <button
            mat-flat-button
            class="action-button icon-button"
            options-id="DELETE_INCIDENT"
            [option]="row.id">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let code; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
