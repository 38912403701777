<input type="hidden" [value]="utcValue" />
<!-- Hidden input with the UTC value -->
<mat-form-field class="full-width-input">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    matInput
    type="time"
    [value]="displayValue"
    (input)="onInputChange($event)" />
  <!-- I don't know why we want to display UTC time in the hint -->
  <mat-hint *ngIf="!hideUtc && !!utcValue" class="timezone-hint" align="end"
    >UTC Time: {{utcValue}}</mat-hint
  >
</mat-form-field>
