import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../app-material.module';
import { AppBehaviorCodeEntryCreateModalComponent } from './behavior-code-entry-create-modal/behavior-code-entry-create-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppConfirmedScheduledReactionsComponent } from './behavior-code-entry-create-modal/confirmed-schedule-reactions/confirmed-scheduled-reactions.component';
import { BehaviorCodeEntryConfirmReactionsComponent } from './behavior-code-entry-confirm-reactions/behavior-code-entry-confirm-reactions.component';
import { BehaviorCodeEntryEditReactionComponent } from './behavior-code-entry-confirm-reactions/behavior-code-entry-edit-reaction/behavior-code-entry-edit-reaction.component';
import { BehaviorCodeEntryConfirmReactionTableComponent } from './behavior-code-entry-confirm-reactions/behavior-code-entry-confirm-reaction-table/behavior-code-entry-confirm-reaction-table.component';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppBehaviorCodeEntryEditReactionModalComponent } from './behavior-code-entry-edit-reaction-modal/behavior-code-entry-edit-reaction-modal.component';
import { NgOptimizedImage } from '@angular/common';
import { AppStudentProfilePhotoModule } from '../../shared/student-profile-photo/student-profile-photo.module';
import { FastTrackModalComponent } from './fast-track-modal/fast-track-modal.component';
import { AppFileUploadModule } from '../../shared/file-upload/file-upload.module';
import { AppUploadBehaviorCodeEntryModalComponent } from './upload-behavior-code-entry-modal/upload-behavior-code-entry-modal.component';

@NgModule({
  imports: [
    AppMaterialModule,
    ReactiveFormsModule,
    AppErrorDisplayComponent,
    AppPaginationModule,
    AppStudentProfilePhotoModule,
    DirectiveModule,
    FontAwesomeModule,
    NgOptimizedImage,
    AppFileUploadModule
  ],
  declarations: [
    AppBehaviorCodeEntryCreateModalComponent,
    AppConfirmedScheduledReactionsComponent,
    BehaviorCodeEntryConfirmReactionsComponent,
    BehaviorCodeEntryEditReactionComponent,
    BehaviorCodeEntryConfirmReactionTableComponent,
    AppBehaviorCodeEntryEditReactionModalComponent,
    FastTrackModalComponent,
    AppUploadBehaviorCodeEntryModalComponent
  ],
  exports: [AppBehaviorCodeEntryCreateModalComponent]
})
export class AppBehaviorCodeEntryModule {}
