"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentListResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const enums_1 = require("../../enums");
class IncidentListResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return [
            'id',
            'incidentNumber',
            'date',
            'status',
            'description',
            'incidentType',
            'reporter'
        ];
    }
    id;
    incidentNumber;
    date;
    description;
    status;
    reporter;
    incidentType;
    static mapIncidents(entities) {
        return entities.map((entity) => {
            const response = new IncidentListResponseDto();
            response.mapFields(entity);
            if (entity['reporter']) {
                const { firstName, lastName } = entity['reporter'];
                response.reporter = `${firstName} ${lastName}`;
            }
            if (entity['incidentType']) {
                const { name } = entity['incidentType'];
                response.incidentType = name;
            }
            return response;
        });
    }
}
exports.IncidentListResponseDto = IncidentListResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], IncidentListResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], IncidentListResponseDto.prototype, "incidentNumber", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], IncidentListResponseDto.prototype, "date", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], IncidentListResponseDto.prototype, "description", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], IncidentListResponseDto.prototype, "status", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], IncidentListResponseDto.prototype, "reporter", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], IncidentListResponseDto.prototype, "incidentType", void 0);
