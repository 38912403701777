<app-error-boundary [componentTemplate]="appContainer"></app-error-boundary>
<ng-template #appContainer>
  <!-- App Navbar -->
  <app-navbar></app-navbar>

  <!-- App Loading Bar -->
  <mat-progress-bar
    *ngIf="isLoading"
    class="app-loading-bar"
    color="primary"
    mode="indeterminate"></mat-progress-bar>

  <!-- App Content -->
  <div #appContentScrollContainer [class]="isLogin ? '' : 'app-content'">
    <!-- App Content -->
    <div
      #appContentScrollContainer
      [class]="isLogin ? '' : 'app-content'"
      [ngClass]="isLoading ? 'app-loading' : ''"
      (click)="navbarService.closeNavbar()">
      <!-- Page Header -->
      <app-page-header *ngIf="isLoggedIn"></app-page-header>
      <!--// All Views Get Injected Here By The Router //-->
      <router-outlet></router-outlet>
    </div>
    <app-save-bar></app-save-bar>
  </div>

  <!-- Extra Initialized Components -->
  <app-support-buttons
    [showWidgets]="showSupportButtons()"></app-support-buttons>
</ng-template>
