"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentCreateDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_validator_1 = require("class-validator");
const incident_participation_create_edit_dto_1 = require("./incident-participation-create-edit.dto");
const class_transformer_1 = require("class-transformer");
class IncidentCreateDto extends mappable_base_1.MappableBase {
    constructor(incidentOptions) {
        super();
        if (!incidentOptions)
            return;
        Object.assign(this, incidentOptions);
    }
    getFields() {
        return [
            'date',
            'schoolId',
            'incidentTypeId',
            'incidentPlaceId',
            'description'
        ];
    }
    date;
    schoolId;
    incidentTypeId;
    incidentPlaceId;
    description;
    participants;
}
exports.IncidentCreateDto = IncidentCreateDto;
__decorate([
    (0, class_validator_1.IsDate)(),
    __metadata("design:type", Date)
], IncidentCreateDto.prototype, "date", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], IncidentCreateDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], IncidentCreateDto.prototype, "incidentTypeId", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], IncidentCreateDto.prototype, "incidentPlaceId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    __metadata("design:type", String)
], IncidentCreateDto.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => incident_participation_create_edit_dto_1.IncidentParticipationCreateEditDto),
    __metadata("design:type", Array)
], IncidentCreateDto.prototype, "participants", void 0);
