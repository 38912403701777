<mat-card appearance="outlined" class="dashboard-container mat-elevation-z3">
  <mat-card-header class="card-header-with-buttons">
    <mat-card-title>Top 5 Behavior Codes</mat-card-title>
    <div class="card-header-buttons">
      <button
        mat-button
        class="card-header-button"
        (click)="onIntervalGroupChange('monthly')">
        Monthly
      </button>
      <button
        mat-button
        class="card-header-button"
        (click)="onIntervalGroupChange('quarterly')">
        Quarterly
      </button>
    </div>
  </mat-card-header>
  <mat-card-content class="dashboard-container-content">
    <div class="chart-container" *ngIf="chartOptions !== null">
      <div class="chart">
        <apx-chart
          #chart
          [colors]="chartOptions.colors"
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [xaxis]="chartOptions.xaxis"
          [yaxis]="chartOptions.yaxis"
          [plotOptions]="chartOptions.plotOptions"
          [dataLabels]="chartOptions.dataLabels"
          [stroke]="chartOptions.stroke"
          [tooltip]="chartOptions.tooltip"
          [legend]="chartOptions.legend"></apx-chart>
      </div>
    </div>
  </mat-card-content>
</mat-card>
