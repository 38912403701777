import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { IIncidentListFilter } from '../incident-list-models/incident-list-filters.interface';
import {
  IDisplayData,
  IncidentStatusEnum
} from '@whetstoneeducation/hero-common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-incident-list-filters',
  templateUrl: './incident-list-filters.component.html',
  styleUrls: ['./incident-list-filters.component.scss']
})
export class IncidentListFiltersComponent extends BaseComponent {
  @Input() filters: IIncidentListFilter;
  @Input() incidentTypeOptions: IDisplayData[];
  @Input() userOptions: IDisplayData[];
  public incidentStatusOptions: IDisplayData[] = Object.values(
    IncidentStatusEnum
  ).map((status) => ({ value: status, display: status }));

  @Output() filtersUpdated = new EventEmitter<IIncidentListFilter>();

  constructor() {
    super();
  }

  public onDateUpdated(
    field: string,
    event: MatDatepickerInputEvent<Date>
  ): void {
    const value = event.target.value;
    const date = !value
      ? null
      : field === 'endDate'
      ? value.setHours(23, 59, 59, 999)
      : value.setHours(0, 0, 0, 0);

    this.filters[field] = date ? value : null;
    this.filtersUpdated.emit(this.filters);
  }

  public onFilterUpdated(field: string, data?: MatSelectChange | number): void {
    const value = data instanceof MatSelectChange ? data.value : data;
    this.filters[field] = value || undefined;
    this.filtersUpdated.emit(this.filters);
  }
}
