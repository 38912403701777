import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AppCustomRostersService } from '../custom-rosters.service';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import {
  CustomRostersResponseDto,
  CustomRostersUpdateDto,
  StudentPerformanceDto
} from '@whetstoneeducation/hero-common';
import { AppStudentsService } from '../../students/students.service';
import { AddStudentColumnsEnum } from '../custom-roster-models/add-student-columns.enum';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddStudentsModalComponent } from './add-students-modal/add-students-modal.component';
import { AppCreateEditCustomRosterModalComponent } from '../create-edit-custom-roster-modal/create-edit-custom-roster-modal.component';

@Component({
  selector: 'app-custom-roster-manage',
  templateUrl: './custom-roster-manage.component.html',
  styleUrls: ['./custom-roster-manage.component.scss']
})
export class AppCustomRosterManageComponent
  extends BaseComponent
  implements OnDestroy
{
  public customRoster: CustomRostersResponseDto;
  public students: StudentPerformanceDto[];
  public pageHeaderSubscription: Subscription;
  public search = '';
  public grade = '';
  constructor(
    public route: ActivatedRoute,
    public customRosterService: AppCustomRostersService,
    public studentsService: AppStudentsService,
    public toastService: AppToastManagerService,
    public pageHeaderService: AppPageHeaderService,
    public router: Router,
    public dialog: MatDialog
  ) {
    super();

    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          switch (action) {
            case HeaderButtonAction.ADD_STUDENTS:
              this.dialog
                .open(AddStudentsModalComponent, {
                  width: '800px',
                  height: '600px',
                  panelClass: 'default-dialog',
                  data: {
                    customRoster: this.customRoster,
                    onClose: async () => {
                      this.customRoster =
                        await this.customRosterService.getCustomRoster(
                          this.customRoster.id
                        );
                    }
                  }
                })
                .afterClosed()
                .subscribe(async () => {
                  this.isLoading = true;
                  this.customRoster =
                    await this.customRosterService.getCustomRoster(
                      this.customRoster.id
                    );
                  this.students = this.customRoster.students;
                  this.isLoading = false;
                });
              break;
            case HeaderButtonAction.EDIT:
              this.dialog.open(AppCreateEditCustomRosterModalComponent, {
                width: '800px',
                height: '600px',
                panelClass: 'default-dialog',
                data: {
                  customRoster: this.customRoster,
                  onClose: async () => {
                    this.isLoading = true;
                    this.customRoster =
                      await this.customRosterService.getCustomRoster(
                        this.customRoster.id
                      );
                    this.students = this.customRoster.students;
                    this.isLoading = false;
                  }
                }
              });
              break;
            case HeaderButtonAction.NAVIGATE:
              await this.router.navigate(['/custom-rosters'], {
                queryParams: { id: this.customRoster.id }
              });
              break;
          }
        }
      );

    this.loadData();
  }

  ngOnDestroy(): void {
    this.pageHeaderSubscription.unsubscribe();
  }

  public get displayedColumns() {
    return Object.values(AddStudentColumnsEnum);
  }

  public columnValues = {
    [AddStudentColumnsEnum.STUDENT_NAME]: ['Student Name'],
    [AddStudentColumnsEnum.STUDENT_ID]: ['Student ID'],
    [AddStudentColumnsEnum.GRADE]: ['Grade'],
    [AddStudentColumnsEnum.ACTION]: ['Remove']
  };

  loadData() {
    this.customRoster = this.route.snapshot.data
      .data as CustomRostersResponseDto;
    this.students = this.customRoster.students;
  }

  public async removeStudent(studentId: number): Promise<void> {
    this.isLoading = true;
    try {
      await this.customRosterService.updateCustomRoster(
        this.customRoster.id,
        new CustomRostersUpdateDto({ studentId })
      );
      this.customRoster = await this.customRosterService.getCustomRoster(
        this.customRoster.id
      );
      this.students = this.customRoster.students;
    } catch (err) {
      this.toastService.error('Error removing student!');
    } finally {
      this.isLoading = false;
    }
  }

  filter() {
    let students = this.customRoster.students;
    if (this.search !== '') {
      students = students.filter((a) => {
        return (
          a.firstName.includes(this.search) ||
          a.lastName.includes(this.search) ||
          a.studentExtId.includes(this.search)
        );
      });
    }
    if (this.grade !== '') {
      students = students.filter((a) => {
        return a.grade.includes(this.grade);
      });
    }
    this.students = students;
  }

  protected readonly columns = AddStudentColumnsEnum;
}
