<form
  class="behavior-code-reaction beahvior-code-reaction-default"
  [formGroup]="defaultBehaviorCodeReactionForm"
  novalidate>
  <mat-card
    appearance="outlined"
    class="behavior-code-reaction-form mat-elevation-z3">
    <mat-card-header class="behavior-code-reaction-form-header card-header">
      <mat-card-title>
        <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
        {{title}}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="behavior-code-reaction-form-content">
      <div class="form-row">
        <mat-form-field class="full-width-input">
          <mat-label>Reaction</mat-label>
          <mat-select formControlName="reactionId">
            <mat-option
              *ngFor="let type of reactionOptions"
              [value]="type.value">
              {{type.display}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-form-field class="full-width-input" subscriptSizing="dynamic">
          <mat-label>Remove or add points</mat-label>
          <input matInput formControlName="points" type="number" />
          <app-error-display
            [form]="defaultBehaviorCodeReactionForm"
            controlName="points"></app-error-display>
        </mat-form-field>
        <div class="help-text">
          <small>
            This action will add or remove the selected amount of points from a
            tracked student. Use negative numbers for removing points.
          </small>
        </div>
      </div>

      <div class="form-row">
        <mat-form-field class="full-width-input" subscriptSizing="dynamic">
          <mat-label>Repeat action X times when rule met</mat-label>
          <input matInput formControlName="reactionMultiplier" type="number" />
          <app-error-display
            [form]="defaultBehaviorCodeReactionForm"
            controlName="reactionMultiplier"></app-error-display>
        </mat-form-field>
        <div class="help-text">
          <small>
            This will multiply the action by the number selected. For example,
            if you select 2 for a detention, the student will be given 2
            detentions. This should not be changed in most cases.
          </small>
        </div>
      </div>

      <div class="form-row">
        <mat-form-field class="full-width-input" subscriptSizing="dynamic">
          <mat-label>Print X copies of each pass</mat-label>
          <input matInput formControlName="printCopies" type="number" />
          <app-error-display
            [form]="defaultBehaviorCodeReactionForm"
            controlName="printCopies"></app-error-display>
        </mat-form-field>
        <div class="help-text">
          <small>
            This will determine how many passes are printed by default when this
            action is used.
          </small>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
