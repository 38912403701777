import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppBehaviorCodeEntryService } from '../behavior-code-entry.service';
import {
  IRelation,
  PrivilegeEnum,
  UploadBehaviorOperationModeType
} from '@whetstoneeducation/hero-common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { AppStudentsService } from '../../students/students.service';
import { Router } from '@angular/router';
import { AppPrivilegesService } from '../../auth/privileges.service';
import { IBehaviorCodeEntryUploadFormControlData } from '../behavior-code-entry-create-modal/behavior-code-entry-create.models';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-upload-code-entry-modal',
  templateUrl: './upload-behavior-code-entry-modal.template.html',
  styleUrls: ['./upload-behavior-code-entry-modal.scss']
})
export class AppUploadBehaviorCodeEntryModalComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public title: string = 'Upload Behavior Tracking';
  public operatingMode: UploadBehaviorOperationModeType;
  public uploadReady = false;
  public fileContent: File;
  public isLoading = false;
  public behaviorCodeTypeOptions: IRelation[];
  public behaviorCodeOptions: IRelation[];
  public fileTypeSelector = new FormControl<string>('');
  public behaviorCodeTypeIdControl = new FormControl<number>(
    null,
    Validators.required
  );
  public behaviorCodeIdControl = new FormControl<number>(
    null,
    Validators.required
  );
  public notesControl = new FormControl<string>('');

  public behaviorCodeEntryForm: FormGroup<IBehaviorCodeEntryUploadFormControlData>;

  public onClose: () => void;

  private destroy$ = new Subject<void>();

  constructor(
    public toastService: AppToastManagerService,
    public behaviorCodeEntryService: AppBehaviorCodeEntryService,
    public studentsService: AppStudentsService,
    public privilegesService: AppPrivilegesService,
    public dialogRef: MatDialogRef<AppUploadBehaviorCodeEntryModalComponent>,
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      studentIds: number[];
      onClose?: () => void;
    },
    public router: Router
  ) {
    super({ privilegesService });
    if (this.data.onClose) {
      this.onClose = this.data.onClose;
    }
  }

  public handleFileChanged(file: File): void {
    if (file) {
      this.isLoading = true;
      this.fileContent = file;
      this.isLoading = false;
    }
  }

  async onFileTypeChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    if (['student-behavior', 'student-points', 'student'].includes(value)) {
      this.operatingMode = (event.target as HTMLInputElement)
        .value as UploadBehaviorOperationModeType;
    }
  }

  private isUploadReady(): boolean {
    if (this.operatingMode === 'student' && this.behaviorCodeIdControl.value) {
      return true;
    } else if (this.operatingMode === 'student-behavior') {
      return true;
    } else if (
      this.operatingMode === 'student-points' &&
      this.behaviorCodeIdControl.value
    ) {
      return true;
    }
  }

  async ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async ngOnInit() {
    this.isLoading = true;
    try {
      const user = this.StorageManager.getLoggedInUser();
      this.behaviorCodeTypeOptions =
        await this.behaviorCodeEntryService.getBehaviorCodeTypesWithBehaviorCodes(
          user.schoolGroupId
        );
    } catch (error) {
      this.toastService.error(error.message);
      this.dialogRef.close();
    }

    this.fileTypeSelector.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (value) => {
        this.isLoading = true;
        this.operatingMode = value as UploadBehaviorOperationModeType;
        this.uploadReady = this.isUploadReady();
        this.isLoading = false;
      });

    this.isLoading = false;
    this.behaviorCodeTypeIdControl.valueChanges.subscribe(async (value) => {
      this.isLoading = true;
      try {
        this.behaviorCodeOptions =
          await this.behaviorCodeEntryService.getBehaviorCodesByType(value);
        this.title = 'Select a Behavior Code: ';
      } catch (error) {
        this.toastService.error(error.message);
        this.dialogRef.close();
      }
      this.isLoading = false;
    });

    this.behaviorCodeIdControl.valueChanges.subscribe(async (value) => {
      this.isLoading = true;
      this.behaviorCodeEntryForm = this.formBuilder.group({
        behaviorCodeControl: [
          this.behaviorCodeOptions.find(
            (behaviorCode) => behaviorCode.id === value
          ),
          Validators.required
        ],
        // placeholder value for now, will replace when select component loads
        reactionControl: [
          {
            id: -1,
            name: 'Loading...'
          },
          Validators.required
        ],
        scheduledReactionControl: [
          {
            id: -1,
            name: 'Loading...'
          }
        ],
        dateControl: [new Date(), Validators.required],
        notesControl: [this.notesControl.value]
      });
      this.title = 'Confirm Reactions: ';
      this.isLoading = false;
    });
  }

  async submit() {
    this.isLoading = true;
    try {
      const uploadResult: Observable<Object> =
        await this.behaviorCodeEntryService.uploadBehaviorCodeEntries(
          this.fileContent,
          this.operatingMode,
          this.behaviorCodeIdControl.value || 0,
          this.notesControl.value
        );
      if (uploadResult) {
        uploadResult.pipe(takeUntil(this.destroy$)).subscribe((result) => {
          this.toastService.success('File uploaded successfully');
        });
      }
    } catch (error) {
      this.toastService.error(error.message);
    }

    this.isLoading = false;

    this.dialogRef.close();
  }

  protected readonly PrivilegeEnum = PrivilegeEnum;
}
