import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { IIncidentCreateEditResolverData } from './incident-create-edit-resolver-data.interface';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppIncidentService } from '../incident.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AppIncidentCreateEditResolver extends BaseResolver<
  IIncidentCreateEditResolverData | boolean
> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public incidentService: AppIncidentService,
    public router: Router
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot
  ): Promise<IIncidentCreateEditResolverData | boolean> {
    const incidentId = route.params.id;

    if (incidentId === 'new') {
      try {
        const incident = await this.incidentService.createIncident();
        return await this.router.navigate(['/incident', incident.id]);
      } catch (error) {
        await this.resolverErrorHandler.handleError(
          error,
          'Error creating incident'
        );
        return false;
      }
    }

    return this.incidentService.getIncidentCreateEditResolverData(incidentId);
  }
}
