"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoolToIntStringTransform = void 0;
const class_transformer_1 = require("class-transformer");
function BoolToIntStringTransform() {
    return function (target, propertyName) {
        (0, class_transformer_1.Transform)(({ value }) => {
            // convert a boolean value to a string like '1' or '0' for database storage
            // if value is a number or string, return it as is
            // if value is not a boolean, return it as is
            if (typeof value === 'boolean') {
                return value ? '1' : '0';
            }
            return value;
        })(target, propertyName);
    };
}
exports.BoolToIntStringTransform = BoolToIntStringTransform;
