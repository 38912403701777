import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  IDisplayData,
  IncidentListResponseDto
} from '@whetstoneeducation/hero-common';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppIncidentService } from '../incident.service';
import { IIncidentListResolverData } from './incident-list-models/incident-list-resolver.interface';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { IIncidentListFilter } from './incident-list-models/incident-list-filters.interface';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';

@Component({
  selector: 'app-incident-list',
  templateUrl: './incident-list.component.html',
  styleUrls: ['./incident-list.component.scss']
})
export class AppIncidentListComponent
  extends BaseComponent
  implements OnDestroy
{
  public incidents: IncidentListResponseDto[];
  public filters: IIncidentListFilter;
  public hasSelectedSchool: boolean;
  public incidentTypeOptions: IDisplayData[];
  public userOptions: IDisplayData[];
  isLoading = true;
  public subscriptions = [];

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public incidentService: AppIncidentService,
    public pageHeaderService: AppPageHeaderService,
    public router: Router
  ) {
    super();
    this.loadData();
    this.subscriptions.push(
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          switch (action) {
            case HeaderButtonAction.CREATE:
              await this.router.navigate(['/incident/new']);
              break;
            case HeaderButtonAction.EDIT:
              await this.router.navigate(['/incidents/config']);
              break;
            default:
              break;
          }
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public loadData() {
    this.isLoading = true;
    const { incidents, incidentTypeOptions, userOptions, defaultFilters } = this
      .route.snapshot.data.data as IIncidentListResolverData;
    this.incidents = incidents.results;
    const tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.INCIDENT_LIST
    );
    tableFilters.count = incidents.options.totalItems;
    this.userOptions = userOptions;
    this.incidentTypeOptions = incidentTypeOptions;

    this.filters = defaultFilters;
    this.filters.tableFilters = tableFilters;
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    await this.updateIncidentList();
    this.isLoading = false;
  }

  public async updateIncidentList(): Promise<void> {
    try {
      const newData = await this.incidentService.getIncidents(this.filters);
      this.incidents = newData.results;
      this.filters.tableFilters.count = newData.options?.totalItems;
    } catch (error) {
      this.toastService.error('Error fetching incidents');
    }
  }

  public async updateFilters(filters: IIncidentListFilter): Promise<void> {
    this.isLoading = true;
    this.filters = filters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.INCIDENT_LIST,
      this.filters.tableFilters
    );
    await this.updateIncidentList();
    this.isLoading = false;
  }
}
