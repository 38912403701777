<div class="incident-filter-container">
  <mat-form-field class="filter-item">
    <mat-label>Search by incident number</mat-label>
    <fa-icon matPrefix [icon]="['fas', 'magnifying-glass']"></fa-icon>
    <input
      data-test-id="SEARCH_FILTER_INPUT"
      matInput
      (keyup.enter)="
        filters.search = $event.target.value; filtersUpdated.emit(filters)
      " />
  </mat-form-field>

  <mat-form-field class="filter-item">
    <mat-label>Start Date</mat-label>
    <input
      placeholder="Start Date"
      matInput
      [matDatepicker]="startDatePicker"
      type="text"
      (dateChange)="onDateUpdated('startDate', $event)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="startDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #startDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="filter-item">
    <mat-label>End Date</mat-label>
    <input
      placeholder="End Date"
      matInput
      [matDatepicker]="endDatePicker"
      type="text"
      (dateChange)="onDateUpdated('endDate', $event)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="endDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #endDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="filter-item">
    <mat-label> User </mat-label>
    <mat-select
      (selectionChange)="onFilterUpdated('reporterId', $event)"
      placeholder="Reporter">
      <mat-option *ngFor="let option of userOptions" [value]="option.value">
        {{ option.display }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="filter-item">
    <mat-label> Type </mat-label>
    <mat-select
      (selectionChange)="onFilterUpdated('incidentTypeId', $event)"
      placeholder="Type">
      <mat-option
        *ngFor="let option of incidentTypeOptions"
        [value]="option.value">
        {{ option.display }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="filter-item">
    <mat-label> Status </mat-label>
    <mat-select
      (selectionChange)="onFilterUpdated('status', $event)"
      placeholder="Status">
      <mat-option
        *ngFor="let option of incidentStatusOptions"
        [value]="option.value">
        {{ option.display }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
