import { Component } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import {
  DateUtil,
  IncidentListResponseDto
} from '@whetstoneeducation/hero-common';

enum IncidentTableColumnsEnum {
  INCIDENT_NUMBER = 'Incident Number',
  DATE = 'Date',
  TYPE = 'Type',
  REPORTER = 'Reporter',
  DESCRIPTION = 'Description',
  STATUS = 'Status',
  ACTION = 'Action'
}

@Component({
  selector: 'app-incidents-table',
  templateUrl: './incidents-table.component.html',
  styleUrls: ['./incidents-table.component.scss']
})
export class IncidentsTableComponent extends BaseTableComponent<IncidentListResponseDto> {
  public columns = IncidentTableColumnsEnum;
  public get displayedColumns() {
    return Object.values(IncidentTableColumnsEnum);
  }
  constructor() {
    super();
  }

  protected readonly DateUtil = DateUtil;
}
