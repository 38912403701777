import { Injectable } from '@angular/core';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { BaseService } from '../../shared/base-classes/base.service';
import {
  BehaviorCodeEntryCreateDto,
  BehaviorCodeEntryResponseDto,
  BehaviorCodeEntryUpdateDto,
  BehaviorCodeEntryUpdateNotesDto,
  BehaviorCodeResponseDto,
  IDisplayData,
  PaginationResultsDto,
  ReactionEntriesListResponseDto,
  ReactionEntriesUpdateDto,
  RelationDto,
  UploadBehaviorOperationModeType
} from '@whetstoneeducation/hero-common';
import { IBehaviorCodeEntryConfirmReactionsResolverData } from './behavior-code-entry-confirm-reactions/behavior-code-entry-confirm-reaction-models/behavior-code-entry-confirm-reactions-resolver-data.interface';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';
import { ITableFilters } from '../../shared/tables/table-filters/table-filters.interface';
import { AppBehaviorCodesService } from '../behavior-codes/behavior-codes.service';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../env.service';

@Injectable({
  providedIn: 'root'
})
export class AppBehaviorCodeEntryService extends BaseService {
  private readonly API_GATEWAY_URL: string;
  constructor(
    public clientDataService: AppClientDataService,
    public behaviorCodeService: AppBehaviorCodesService,
    public http: HttpClient,
    private envService: EnvService
  ) {
    super();
    this.API_GATEWAY_URL = this.envService.apiGatewayUrl;
  }

  public async getBehaviorCodeOptions(
    schoolId: number
  ): Promise<IDisplayData[]> {
    return this.clientDataService.execute<IDisplayData[]>(
      this.GET_ROUTES.BEHAVIOR_CODE_DROPDOWN,
      {
        method: this.METHOD.GET,
        pathParams: {
          schoolId
        }
      }
    );
  }

  public async getBehaviorCodeTypes(
    schoolGroupId?: number
  ): Promise<RelationDto[]> {
    return this.clientDataService.execute<RelationDto[]>(
      this.GET_ROUTES.BEHAVIOR_CODE_TYPES_LIST,
      {
        queryParams: {
          schoolGroupId: schoolGroupId
        }
      }
    );
  }

  public async getBehaviorCodeTypesWithBehaviorCodes(
    schoolGroupId?: number
  ): Promise<RelationDto[]> {
    return this.clientDataService.execute<RelationDto[]>(
      this.GET_ROUTES.BEHAVIOR_CODE_TYPES_LIST_WITH_CODES,
      {
        queryParams: {
          schoolGroupId: schoolGroupId
        }
      }
    );
  }

  public async getBehaviorCodesByType(
    behaviorCodeTypeId: number
  ): Promise<RelationDto[]> {
    return this.clientDataService.execute<RelationDto[]>(
      this.GET_ROUTES.BEHAVIOR_CODES_LIST_BY_BEHAVIOR_CODE_TYPE,
      {
        pathParams: {
          behaviorCodeTypeId
        }
      }
    );
  }

  public async getReactionOptions(
    behaviorCodeId: number,
    studentId: number
  ): Promise<RelationDto[]> {
    return this.clientDataService.execute<RelationDto[]>(
      this.GET_ROUTES.BEHAVIOR_CODE_NEXT_POSSIBLE_REACTION_OPTIONS,
      {
        queryParams: {
          behaviorCodeId,
          studentId
        }
      }
    );
  }

  public async getScheduledReactionsOptions(
    behaviorCodeReactionId: number,
    studentId: number
  ): Promise<RelationDto[]> {
    return this.clientDataService.execute<RelationDto[]>(
      this.GET_ROUTES.BEHAVIOR_CODE_REACTION_SCHEDULED_REACTION_OPTIONS,
      {
        queryParams: {
          behaviorCodeReactionId,
          studentId
        }
      }
    );
  }

  public async uploadBehaviorCodeEntries(
    file: File,
    operationMode: UploadBehaviorOperationModeType,
    behaviorCodeId: number,
    notes?: string
  ) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    let urlPart = `/${operationMode}?behaviorCodeId=${behaviorCodeId}`;
    urlPart = notes ? `${urlPart}&notes=${notes}` : urlPart;
    return this.http.post(
      this.API_GATEWAY_URL +
        this.POST_ROUTES.UPLOAD_BEHAVIOR_CODE_ENTRIES +
        urlPart,
      formData,
      { headers: { Version: '1' }, withCredentials: true }
    );
  }

  public async batchCreateBehaviorCodeEntries(
    behaviorCodeEntryCreateDtos: BehaviorCodeEntryCreateDto[]
  ): Promise<BehaviorCodeEntryResponseDto[]> {
    return this.clientDataService.execute<BehaviorCodeEntryResponseDto[]>(
      this.POST_ROUTES.BATCH_CREATE_BEHAVIOR_CODE_ENTRIES,
      {
        method: this.METHOD.POST,
        body: behaviorCodeEntryCreateDtos
      }
    );
  }

  public async getBehaviorCodeEntryConfirmReactionsResolverData(
    behaviorCodeEntriesIds: number[],
    schoolId: number,
    addPrintInfo?: boolean
  ): Promise<IBehaviorCodeEntryConfirmReactionsResolverData> {
    const tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.COMPLIANCE_LIST
    );
    const reactionEntries = await this.getBehaviorCodeEntriesByIds(
      behaviorCodeEntriesIds,
      schoolId,
      tableFilters,
      addPrintInfo
    );

    // cache behavior codes so we don't have to look them up for each reaction entry
    // if we already have the behavior code, we don't need to look it up again
    // Currently we will only see one behavior code for this list of reaction entries
    // but this code will support multiple if needed
    const behaviorCodes = new Map<number, BehaviorCodeResponseDto>();
    for (const reactionEntry of reactionEntries.results) {
      if (!behaviorCodes.has(reactionEntry.behaviorCodeId)) {
        const behaviorCode = await this.behaviorCodeService.getBehaviorCodeById(
          reactionEntry.behaviorCodeId
        );
        behaviorCodes.set(reactionEntry.behaviorCodeId, behaviorCode);
      }
    }

    return {
      reactionEntries,
      tableFilters,
      behaviorCodes,
      behaviorCodeEntriesIds
    };
  }

  public async getBehaviorCodeEntriesByIds(
    ids: number[],
    schoolId: number,
    tableFilters: ITableFilters,
    addPrintInfo?: boolean
  ): Promise<PaginationResultsDto<ReactionEntriesListResponseDto>> {
    return this.clientDataService.execute<
      PaginationResultsDto<ReactionEntriesListResponseDto>
    >(this.GET_ROUTES.REACTION_ENTRIES_BY_BEHAVIOR_CODE_ENTRY_IDS, {
      queryParams: {
        ids,
        schoolId,
        addPrintInfo,
        ...tableFilters
      }
    });
  }

  public async createBehaviorCodeEntry(
    behaviorCodeEntryCreateDto: BehaviorCodeEntryCreateDto
  ): Promise<BehaviorCodeEntryCreateDto> {
    return this.clientDataService.execute<BehaviorCodeEntryCreateDto>(
      this.POST_ROUTES.CREATE_BEHAVIOR_CODE_ENTRY,
      {
        method: this.METHOD.POST,
        body: behaviorCodeEntryCreateDto
      }
    );
  }

  public async updateReactionEntry(
    id: number,
    dto: ReactionEntriesUpdateDto
  ): Promise<ReactionEntriesListResponseDto> {
    return this.clientDataService.execute<ReactionEntriesListResponseDto>(
      this.PUT_ROUTES.UPDATE_REACTION_ENTRY,
      {
        method: this.METHOD.PUT,
        pathParams: {
          id
        },
        body: dto
      }
    );
  }

  public async getBehaviorCodeEntry(
    id: number
  ): Promise<BehaviorCodeEntryResponseDto> {
    return this.clientDataService.execute<BehaviorCodeEntryResponseDto>(
      this.GET_ROUTES.BEHAVIOR_CODE_ENTRY_BY_ID,
      {
        method: this.METHOD.GET,
        pathParams: {
          id
        }
      }
    );
  }

  public async deleteBehaviorCodeEntry(
    id: number
  ): Promise<BehaviorCodeEntryResponseDto> {
    const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    if (!schoolId) {
      throw new Error('User does not have a current school.');
    }
    return this.clientDataService.execute<BehaviorCodeEntryResponseDto>(
      this.DELETE_ROUTES.DELETE_BEHAVIOR_CODE_ENTRY,
      {
        method: this.METHOD.DELETE,
        pathParams: {
          id
        },
        queryParams: {
          schoolId
        }
      }
    );
  }

  public async updateBehaviorCodeEntry(
    updateDto: BehaviorCodeEntryUpdateDto,
    id: number
  ): Promise<BehaviorCodeEntryResponseDto> {
    return this.clientDataService.execute<BehaviorCodeEntryResponseDto>(
      this.PUT_ROUTES.UPDATE_BEHAVIOR_CODE_ENTRY,
      {
        method: this.METHOD.PUT,
        pathParams: { id },
        body: updateDto
      }
    );
  }

  public async updateBehaviorCodeEntryNotes(
    updateDto: BehaviorCodeEntryUpdateNotesDto,
    id: number
  ): Promise<BehaviorCodeEntryResponseDto> {
    return this.clientDataService.execute<BehaviorCodeEntryResponseDto>(
      this.PUT_ROUTES.UPDATE_BEHAVIOR_CODE_ENTRY_NOTES,
      {
        method: this.METHOD.PUT,
        pathParams: { id },
        body: updateDto
      }
    );
  }

  public async getFastTrackBehaviorCodes(): Promise<BehaviorCodeResponseDto[]> {
    return this.clientDataService.execute<BehaviorCodeResponseDto[]>(
      this.GET_ROUTES.BEHAVIOR_CODES_FAST_TRACK
    );
  }
}
