import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute } from '@angular/router';
import { AppUsersServices } from '../users.service';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { IUsersListResolverData } from './users-list-models/users-list-resolver-data.interface';
import { IUsersListFilters } from './users-list-models/users-list-filters.interface';
import { logger } from '../../../shared/logger';
import { UserBasicResponseDto } from '@whetstoneeducation/hero-common';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { Subject, takeUntil } from 'rxjs';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import dayjs from 'dayjs';
import { AppConfirmationDialogService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { DEFAULT_DIALOG_RESULT } from '../../../shared/confirmation-dialog/confirmation-dialog-options.interface';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.template.html',
  styleUrls: ['./users-lists.scss']
})
export class AppUsersListComponent extends BaseComponent implements OnInit {
  public users: UserBasicResponseDto[];
  public filters: IUsersListFilters;
  private destroy$ = new Subject<void>();

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public usersServices: AppUsersServices,
    public pageHeaderService: AppPageHeaderService,
    public confirmationDialogService: AppConfirmationDialogService
  ) {
    super();
    this.loadData();
  }

  public ngOnInit(): void {
    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action) => {
        if (action === HeaderButtonAction.ACTIVATE) {
          if (this.isLoading) return; // Check both flags

          this.isLoading = true;
          console.log('Activating guardians');

          // check the settings for the activation process
          const user = this.StorageManager.getLoggedInUser();
          const lastActivated = dayjs(user.settings.guardians_mass_activated);
          let hasRecentlyActivated = false;
          // if the last activation was in less than 24 hours do not allow another activation
          if (
            lastActivated.isValid() &&
            lastActivated.isAfter(dayjs().subtract(1, 'days'))
          ) {
            this.toastService.error(
              'You have recently activated guardians. Please wait 24 hours before trying again.'
            );
            this.isLoading = false;
            return;
          }

          // if the last activation was less than 90 days ago, set flag and prompt user for confirmation
          if (
            lastActivated.isValid() &&
            lastActivated.isAfter(dayjs().subtract(90, 'days'))
          ) {
            hasRecentlyActivated = true;
            const confirm = await this.confirmationDialogService.confirm({
              title: 'Activate Guardians',
              content: `The guardians were last activated on ${dayjs(lastActivated).format('MM/DD/YYYY')}.
                Are you sure you want to do this again now? You will be prompted to confirm one more time.`,
              actions: [
                {
                  key: DEFAULT_DIALOG_RESULT.CANCEL,
                  label: 'No',
                  classes: 'btn btn-danger',
                  icon: 'times-circle'
                },
                {
                  key: DEFAULT_DIALOG_RESULT.YES,
                  label: 'Yes',
                  classes: 'btn btn-success',
                  icon: 'check-circle'
                }
              ]
            });
            if (
              confirm === DEFAULT_DIALOG_RESULT.CANCEL ||
              confirm !== DEFAULT_DIALOG_RESULT.YES
            ) {
              this.isLoading = false;
              return;
            }
          }
          // set message for activation. If we have recently activated we will
          // remind the user again here since this will mass email all parents
          // in the school again.
          let message = 'Are you sure you want to activate all guardians?';
          if (hasRecentlyActivated) {
            message +=
              ' This will send another activation email to all guardians in the ' +
              'school that have not yet logged in.';
          }

          const confirm = await this.confirmationDialogService.confirm({
            title: 'Activate Guardians',
            content: message,
            actions: [
              {
                key: DEFAULT_DIALOG_RESULT.CANCEL,
                label: 'No',
                classes: 'btn btn-danger',
                icon: 'times-circle'
              },
              {
                key: DEFAULT_DIALOG_RESULT.YES,
                label: 'Yes',
                classes: 'btn btn-success',
                icon: 'check-circle'
              }
            ]
          });

          if (
            confirm === DEFAULT_DIALOG_RESULT.CANCEL ||
            confirm !== DEFAULT_DIALOG_RESULT.YES
          ) {
            this.isLoading = false;
            return;
          }

          try {
            // set the guardians_mass_activated setting date to now.
            // this only modifies the user object in memory.
            // this is to prevent the user from clicking the button multiple times
            // and sending multiple emails. The api call will handle the actual
            // activation and settings update
            // The api attempts to prevent multiple activations by checking the
            // running jobs, but that is not foolproof as the job itself can complete
            // fairly quickly and the user could click the button again before
            // all emails actually complete sending (which can take a while,
            // especially for large schools, approx 0.5-1s per email)
            user.settings.guardians_mass_activated = new Date().toISOString();
            this.StorageManager.saveUserInfo(user);
            await this.usersServices.activateGuardians();
          } finally {
            this.isLoading = false;
          }
        }
      });
  }

  public loadData(): void {
    this.isLoading = true;
    const data = this.route.snapshot.data.data as IUsersListResolverData;
    this.users = data.users.results;
    this.filters = data.defaultFilters;
    this.filters.tableFilters.count = data.users.options.totalItems;
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    await this.updateUserList(this.filters);
    this.isLoading = false;
  }

  public async updateUserList(filters: IUsersListFilters): Promise<void> {
    try {
      const newData = await this.usersServices.getUsersList(filters);
      this.users = newData.results;
      this.filters.tableFilters.count = newData.options.totalItems;
    } catch (err) {
      this.toastService.error('Error retrieving users!');
      logger.error(err);
    }
  }

  public async updateFilters(newFilters: IUsersListFilters): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.USERS_LIST,
      this.filters.tableFilters
    );
    await this.updateUserList(this.filters);
    this.isLoading = false;
  }
}
