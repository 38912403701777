import { ICustomRoute } from '../../app-routing.module';
import { AppIncidentConfigResolver } from './incident-config/incident-config.resolver';
import { AppIncidentConfigComponent } from './incident-config/incident-config.component';
import { AuthGuard } from '../../route-guards/auth.guard';
import { AppIncidentCreateEditResolver } from './incident-create-edit/incident-create-edit.resolver';
import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { IncidentCreateEditComponent } from './incident-create-edit/incident-create-edit.component';
import { AppIncidentListComponent } from './incident-list/incident-list.component';
import { AppIncidentListResolver } from './incident-list/incident-list.resolver';

export const AppIncidentRoutes: ICustomRoute[] = [
  {
    path: 'incidents/config',
    title: 'Incident Config',
    resolve: { data: AppIncidentConfigResolver },
    component: AppIncidentConfigComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Incident Config'
    }
  },
  {
    path: 'incidents',
    title: 'Incidents',
    component: AppIncidentListComponent,
    resolve: { data: AppIncidentListResolver },
    data: {
      title: 'Incidents',
      icon: 'list',
      buttons: [
        {
          name: 'New',
          action: HeaderButtonAction.CREATE,
          style: 'success-button'
        },
        {
          name: 'Config',
          action: HeaderButtonAction.EDIT,
          style: 'success-button'
        }
      ]
    }
  },
  {
    path: 'incident/:id',
    title: 'Incident',
    component: IncidentCreateEditComponent,
    resolve: { data: AppIncidentCreateEditResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Edit Incident',
      icon: 'pencil',
      buttons: [
        {
          name: 'Back',
          action: HeaderButtonAction.BACK,
          style: 'cancel-button'
        },
        {
          name: 'Save',
          action: HeaderButtonAction.SAVE,
          style: 'success-button'
        },
        {
          name: 'Save & Submit',
          action: HeaderButtonAction.SAVE_AND_SUBMIT,
          style: 'success-button'
        }
      ]
    }
  }
];
