import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import {
  DateUtil,
  IDisplayData,
  IncidentParticipantResponseDto,
  IncidentParticipationCreateEditDto,
  IncidentStatusEnum,
  IncidentUpdateDto
} from '@whetstoneeducation/hero-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppIncidentService } from '../incident.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { IIncidentCreateEditResolverData } from './incident-create-edit-resolver-data.interface';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { MatDialog } from '@angular/material/dialog';
import { AddParticipantModalComponent } from './add-participant-modal/add-participant-modal.component';

@Component({
  selector: 'app-incident-create-edit',
  templateUrl: './incident-create-edit.component.html',
  styleUrls: ['./incident-create-edit.component.scss']
})
export class IncidentCreateEditComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public incidentId: number;
  public incident: IncidentUpdateDto;
  public incidentForm: FormGroup;
  public dateTimeForm: FormGroup;
  public incidentPlaceOptions: IDisplayData[] = [];
  public incidentTypeOptions: IDisplayData[] = [];
  public participants: IncidentParticipantResponseDto[] = [];
  private destroy$ = new Subject<void>();
  public timezone: string;
  constructor(
    public incidentService: AppIncidentService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public router: Router,
    private pageHeaderService: AppPageHeaderService,
    public formBuilder: FormBuilder,
    public toastService: AppToastManagerService
  ) {
    super();
  }

  ngOnInit() {
    this.loadData(
      this.route.snapshot.data.data as IIncidentCreateEditResolverData
    );
    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action: HeaderButtonAction) => {
        switch (action) {
          case HeaderButtonAction.SAVE:
            await this.saveChanges();
            break;
          case HeaderButtonAction.SAVE_AND_SUBMIT:
            await this.saveChanges(true);
            break;
          case HeaderButtonAction.BACK:
            await this.router.navigate(['/incidents']);
            break;
          default:
            break;
        }
      });
    this.incidentForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (value) => {
        this.incident = await validateAndGetValue<IncidentUpdateDto>(
          this.incidentForm,
          IncidentUpdateDto
        );
      });
    const user = this.StorageManager.getLoggedInUser();
    this.timezone = user.settings.timezone;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadData(resolverData: IIncidentCreateEditResolverData) {
    this.isLoading = true;
    this.incidentPlaceOptions = resolverData.incidentPlaces;
    this.incidentTypeOptions = resolverData.incidentTypes;

    this.incident = new IncidentUpdateDto();
    this.incident.mapFields(resolverData.incident);
    this.participants = resolverData.incident.participants;
    this.incidentId = resolverData.incident.id;

    this.incident.schoolId = this.StorageManager.getCurrentSchoolId();

    this.incidentForm = dtoToFormGroup(this.incident, this.formBuilder, {
      mapId: false,
      exclude: ['participants', 'schoolId', 'date']
    });

    const date = new Date(resolverData.incident.date);

    this.dateTimeForm = new FormGroup<any>({
      date: this.formBuilder.control(date, Validators.required),
      time: this.formBuilder.control(
        DateUtil.convertTimestampToFormTime(date.getTime()),
        Validators.required
      )
    });

    this.isLoading = false;
  }

  public async saveChanges(submit?: boolean) {
    if (
      formCanSave(this.incidentForm, this.toastService, false) &&
      formCanSave(this.dateTimeForm, this.toastService, false)
    ) {
      this.isLoading = true;
      const { date, time } = this.dateTimeForm.value;
      const timeDateObj = DateUtil.convertFormTimeIntoDate(time);
      this.incident.date = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        timeDateObj.getHours(),
        timeDateObj.getMinutes()
      );
      try {
        this.incident.participants = this.participants.map((p) => {
          return new IncidentParticipationCreateEditDto({
            participantId: p.id,
            type: p.type
          });
        });
        this.incident.schoolId = this.StorageManager.getCurrentSchoolId();
        if (submit) {
          this.incident.status = IncidentStatusEnum.PENDING;
        }
        await this.incidentService.updateIncident(
          this.incidentId,
          this.incident as IncidentUpdateDto
        );
        submit
          ? this.toastService.success('Incident submitted')
          : this.toastService.success('Incident saved');
      } catch (e) {
        this.toastService.error('Error saving incident');
      }
      this.isLoading = false;
    }
  }

  public openAddParticipantModal() {
    const dialogRef = this.dialog.open(AddParticipantModalComponent, {
      data: {
        incidentId: this.incidentId,
        excludedStudentIds: this.participants.map((p) => p.id)
      },
      width: '800px',
      height: '600px'
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result && result.participants) {
          this.participants = this.participants.concat(result.participants);
        }
      });
  }

  public removeParticipant(id: number) {
    this.participants = this.participants.filter((p) => p.id !== id);
  }
}
