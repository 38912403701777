<div class="incident-list-content">
  <div class="content">
    <app-incident-list-filters
      [incidentTypeOptions]="incidentTypeOptions"
      [userOptions]="userOptions"
      [filters]="filters"
      (filtersUpdated)="updateFilters($event)"></app-incident-list-filters>
    <app-incidents-table
      [dataArr]="incidents"
      [isLoading]="isLoading"
      [tableFilters]="filters.tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-incidents-table>
  </div>
</div>
