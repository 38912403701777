"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BehaviorEntryUploadTemplateVariablesDto = void 0;
const notification_templates_1 = require("../notification-templates");
class BehaviorEntryUploadTemplateVariablesDto extends notification_templates_1.BaseTemplateVariablesDto {
    constructor(behaviorEntryUploadTemplateVariablesOptions) {
        super();
        Object.assign(this, behaviorEntryUploadTemplateVariablesOptions);
    }
    mapFields(source, skipNested, skipTransforms) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    getFields() {
        return [
            'fileLength',
            'studentsNotFound',
            'studentsFound',
            'behaviorCodesNotFound',
            'behaviorCodesFound',
            'recordsFound',
            'error'
        ];
    }
    fileLength;
    studentsNotFound;
    studentsFound;
    behaviorCodesNotFound;
    behaviorCodesFound;
    recordsFound;
    error;
}
exports.BehaviorEntryUploadTemplateVariablesDto = BehaviorEntryUploadTemplateVariablesDto;
