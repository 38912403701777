import { Injectable } from '@angular/core';
import { StudentPerformanceDto } from '@whetstoneeducation/hero-common';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { AppCalendarService } from '../calendar/calendar.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppDashboardService extends BaseService {
  constructor(
    public appClientDataService: AppClientDataService,
    public toastService: AppToastManagerService,
    public calendarService: AppCalendarService,
    public router: Router
  ) {
    super();
  }

  public async getDistrictDashboardResolverData(): Promise<{
    topPerformers: StudentPerformanceDto[];
    // TODO: rework schedules after school groups - THOR-380
    todaySchedules: any[];
    noCalendar?: boolean;
    outOfAcademicYear?: boolean;
  }> {
    const user = this.StorageManager.getLoggedInUser();

    if (!user.currentSchoolId)
      return Promise.resolve({
        topPerformers: [],
        todaySchedules: []
      });

    const calendarExists =
      await this.calendarService.checkIfValidCalendarExists(
        user.currentSchoolId
      );

    if (!calendarExists) {
      return {
        topPerformers: [],
        todaySchedules: [],
        noCalendar: true
      };
    }

    const topPerformersCall = this.getTopPerformers(user.currentSchoolId);
    const todaySchedulesCall = this.calendarService.getTodaySchedule(
      user.currentSchoolId
    );

    try {
      const [topPerformers, todaySchedules] = await Promise.all([
        topPerformersCall,
        todaySchedulesCall
      ]);

      return {
        topPerformers,
        todaySchedules: todaySchedules.studentGroupBellScheduleCalendarDays
      };
    } catch (e) {
      if (e.status === 404) {
        return {
          topPerformers: [],
          todaySchedules: [],
          outOfAcademicYear: true
        };
      }
      throw e;
    }
  }

  // this is just a placeholder until we build out the other dashboard resolvers
  public async getGenericDashboardResolverData(): Promise<any> {
    const user = this.StorageManager.getLoggedInUser();
    const calendarExists =
      await this.calendarService.checkIfValidCalendarExists(
        user.currentSchoolId
      );

    if (!calendarExists) {
      return {
        noCalendar: true
      };
    } else {
      return {};
    }
  }

  public async getTopBehaviorCodes(
    interval: 'monthly' | 'quarterly'
  ): Promise<any> {
    return this.appClientDataService.execute<any>(
      this.GET_ROUTES.TOP_BEHAVIOR_CODES,
      {
        pathParams: {
          interval
        }
      }
    );
  }

  public async getTopPerformers(
    schoolId: number
  ): Promise<StudentPerformanceDto[]> {
    return this.appClientDataService.execute<StudentPerformanceDto[]>(
      this.GET_ROUTES.TOP_PERFORMERS,
      {
        pathParams: {
          schoolId
        }
      }
    );
  }
}
