import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent
} from 'ng-apexcharts';
import { AppDashboardService } from '../../dashboard.service';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';

export type ChartOptions = {
  colors: string[];
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-top-behavior-codes',
  templateUrl: './top-behavior-codes.template.html',
  styleUrls: ['./top-behavior-codes.scss']
})
export class AppTopBehaviorCodesComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions?: Partial<ChartOptions> = null;
  public selectedPeriod: 'monthly' | 'quarterly' = 'monthly';
  public behaviorCodes: {
    id: number;
    codeName: string;
    count: number;
    total_count: number;
    intervalGroup: string;
  }[] = [];
  private loadedData: any[];

  constructor(
    private toastService: AppToastManagerService,
    private dashboardService: AppDashboardService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    await this.fetchBehaviorCodes();
    await this.updateChartOptions();
  }

  async fetchBehaviorCodes() {
    this.isLoading = true;
    try {
      this.behaviorCodes = await this.dashboardService.getTopBehaviorCodes(
        this.selectedPeriod
      );
    } catch (error) {
      this.toastService.error('Failed to fetch behavior codes');
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async onIntervalGroupChange(intervalGroup: 'monthly' | 'quarterly') {
    this.selectedPeriod = intervalGroup;
    await this.fetchBehaviorCodes();
    await this.updateChartOptions();
  }

  async updateChartOptions() {
    if (this.behaviorCodes.length === 0) return;

    // Generate the last 12 months or 4 quarters as intervalGroups based on selectedPeriod
    const today = new Date();
    let intervalGroups: string[];

    if (this.selectedPeriod === 'monthly') {
      intervalGroups = Array.from({ length: 12 }, (_, i) => {
        const date = new Date(today);
        date.setMonth(date.getMonth() - i);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      }).reverse();
    } else if (this.selectedPeriod === 'quarterly') {
      intervalGroups = Array.from({ length: 4 }, (_, i) => {
        const date = new Date(today);
        date.setMonth(date.getMonth() - i * 3); // Move back by quarters
        const quarter = Math.floor(date.getMonth() / 3) + 1;
        return `${date.getFullYear()}-Q${quarter}`;
      }).reverse();
    }

    // Group data by codeName
    const groupedData = this.behaviorCodes.reduce(
      (acc, item) => {
        if (!acc[item.codeName]) {
          acc[item.codeName] = [];
        }
        acc[item.codeName].push({
          intervalGroup: item.intervalGroup,
          count: Number(item.count)
        });
        return acc;
      },
      {} as { [key: string]: { intervalGroup: string; count: number }[] }
    );

    // Generate series data
    const series = Object.keys(groupedData).map((codeName) => {
      return {
        name: codeName,
        data: intervalGroups.map((intervalGroup) => {
          const foundItem = groupedData[codeName].find(
            (item) => item.intervalGroup === intervalGroup
          );
          return foundItem ? foundItem.count : 0;
        })
      };
    });

    // Update chart options
    this.chartOptions = {
      series,
      colors: ['#2c89bf', '#07b1ab', '#9cbfe4', '#c2d7f0', '#145c99'],
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '65%',
          borderRadius: this.selectedPeriod === 'monthly' ? 3 : 6,
          borderRadiusApplication: 'end'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: intervalGroups
      },
      yaxis: {
        title: {
          text: 'Count'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: (val) => `${val} times`
        }
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center'
      }
    };
  }
}
