"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivilegeEnum = void 0;
var PrivilegeEnum;
(function (PrivilegeEnum) {
    PrivilegeEnum["VIEW_DAILY_ACTIVITY"] = "view_daily_activity";
    PrivilegeEnum["VIEW_COMMUNICATIONS_REPORT"] = "view_communications_report";
    PrivilegeEnum["DEFAULT"] = "default";
    PrivilegeEnum["TRACK_BEHAVIOR"] = "track_behavior";
    PrivilegeEnum["IMPORT_BEHAVIOR_TRACKING"] = "import_behavior_tracking";
    PrivilegeEnum["EDIT_TRACKED_BEHAVIOR"] = "edit_tracked_behavior";
    PrivilegeEnum["DELETE_TRACKED_BEHAVIOR"] = "delete_tracked_behavior";
    PrivilegeEnum["EDIT_BEHAVIOR_NOTES"] = "edit_behavior_notes";
    PrivilegeEnum["CREATE_BEHAVIOR_NOTES"] = "create_behavior_notes";
    PrivilegeEnum["VIEW_BEHAVIOR_NOTES"] = "view_behavior_notes";
    PrivilegeEnum["MANAGE_BEHAVIOR_CODE"] = "manage_behavior_code";
    PrivilegeEnum["MANAGE_BELL_SCHEDULE"] = "manage_bell_schedule";
    PrivilegeEnum["SET_ACTIVE_BELL_SCHEDULE"] = "set_active_bell_schedule";
    PrivilegeEnum["MANAGE_CALENDAR"] = "manage_calendar";
    PrivilegeEnum["MANAGE_SFTP"] = "manage_sftp";
    PrivilegeEnum["MANAGE_IMPORT_EXPORT"] = "manage_import_export";
    PrivilegeEnum["MANAGE_NOTIFICATION"] = "manage_notification";
    PrivilegeEnum["MANAGE_COMPLIANCE"] = "manage_compliance";
    PrivilegeEnum["MANAGE_ROLE"] = "manage_role";
    PrivilegeEnum["INTERNAL_ADMIN"] = "internal_admin";
    PrivilegeEnum["MANAGE_SCHOOLS"] = "manage_schools";
    PrivilegeEnum["VIEW_STUDENT_GROUPS"] = "view_student_groups";
    PrivilegeEnum["MANAGE_STUDENT_GROUPS"] = "manage_student_groups";
    PrivilegeEnum["MANAGE_STUDENTS"] = "manage_students";
    PrivilegeEnum["MANAGE_USERS"] = "manage_users";
    PrivilegeEnum["EDIT_BEHAVIOR_CODE_CONSEQUENCES"] = "edit_behavior_code_consequences";
    PrivilegeEnum["TRIGGER_MIGRATION"] = "trigger_migration";
    PrivilegeEnum["RESET_STUDENT_PASSWORDS"] = "reset_student_passwords";
    PrivilegeEnum["DEACTIVATE_RECORDS"] = "deactivate_records";
    PrivilegeEnum["CREATE_INCIDENTS"] = "create_incidents";
    PrivilegeEnum["FINALIZE_INCIDENTS"] = "finalize_incidents";
    PrivilegeEnum["MANAGE_INCIDENTS"] = "manage_incidents";
})(PrivilegeEnum || (exports.PrivilegeEnum = PrivilegeEnum = {}));
