import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AppStudentsService } from '../../../students/students.service';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IDisplayData,
  IncidentParticipantResponseDto,
  IncidentParticipationCreateEditDto,
  StudentPerformanceDto
} from '@whetstoneeducation/hero-common';
import { IStudentsListFilters } from '../../../students/students-list-models/students-list-filters.interface';
import { BaseStudentListComponent } from '../../../../shared/base-student-list/base-student-list.component';
import { AddParticipantColumnsEnum } from './add-participant-columns.enum';
import { FormControl, FormGroup } from '@angular/forms';

declare enum IncidentParticipantTypeEnum {
  AGGRESSOR = 'Aggressor',
  WITNESS = 'Witness',
  VICTIM = 'Victim'
}

@Component({
  selector: 'app-add-participant-modal',
  templateUrl: './add-participant-modal.component.html',
  styleUrls: ['./add-participant-modal.component.scss']
})
export class AddParticipantModalComponent
  extends BaseStudentListComponent
  implements OnInit
{
  public incidentTypeOptions: IDisplayData[];
  public search: string;
  public grade: string;
  public filters: IStudentsListFilters;
  public form: FormGroup;
  participantsAdded: IncidentParticipantResponseDto[] = [];
  students: StudentPerformanceDto[] = [];
  constructor(
    public studentsService: AppStudentsService,
    public toastService: AppToastManagerService,
    public dialogRef: MatDialogRef<AddParticipantModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      incidentId: number;
      excludedStudentIds?: number[];
      onClose: (participants: IncidentParticipationCreateEditDto[]) => void;
    }
  ) {
    super(studentsService, toastService);
  }

  async ngOnInit() {
    this.isLoading = true;
    try {
      this.incidentTypeOptions = [
        { value: 'Aggressor', display: 'Aggressor' },
        { value: 'Witness', display: 'Witness' },
        { value: 'Victim', display: 'Victim' }
      ];
      const currentSchoolId = this.StorageManager.getCurrentSchoolId();
      if (!currentSchoolId) {
        this.toastService.error('Please select a school first');
        this.dialogRef.close();
        this.isLoading = false;

        return;
      }

      await this.loadFiltersAndStudents({
        excludeStudentIds: this.data.excludedStudentIds,
        ...(this.data.incidentId ? { incidentId: this.data.incidentId } : {})
      });
      this.form = new FormGroup({});
      // iterate through all the students and add form controls for each student
      this.students.forEach((student) => {
        this.form.addControl(`student-${student.id}`, new FormControl());
      });
    } catch (e) {
      this.toastService.error('issue loading students');
      this.dialogRef.close();
    }

    this.isLoading = false;
  }

  async addParticipant(
    student: StudentPerformanceDto,
    type: IncidentParticipantTypeEnum
  ) {
    const participant = new IncidentParticipantResponseDto();
    participant.mapFields(student);
    participant.type = type;
    this.participantsAdded.push(participant);
    this.students = this.students.filter(
      (student) =>
        !this.participantsAdded.some(
          (participant) => participant.id === student.id
        )
    );
  }

  public async onStudentsUpdated() {
    this.students.forEach((student) => {
      if (!this.form.contains(`student-${student.id}`)) {
        this.form.addControl(`student-${student.id}`, new FormControl());
      }
    });
  }

  handleClose() {
    this.dialogRef.close({ participants: this.participantsAdded });
  }

  public get displayedColumns() {
    return Object.values(AddParticipantColumnsEnum);
  }

  protected readonly columns = AddParticipantColumnsEnum;
}
