import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { BaseService } from '../../shared/base-classes/base.service';
import { Injectable } from '@angular/core';
import {
  IPrivilege,
  IUser,
  LoginResponseDto,
  PaginationResultsDto,
  RoleResponseDto,
  UserBasicResponseDto,
  UserCreateDto,
  UserDetailResponseDto,
  UserUpdateDto
} from '@whetstoneeducation/hero-common';
import { IUsersListFilters } from './users-list/users-list-models/users-list-filters.interface';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';
import { IUsersListResolverData } from './users-list/users-list-models/users-list-resolver-data.interface';
import { logger } from '../../shared/logger';

@Injectable({
  providedIn: 'root'
})
export class AppUsersServices extends BaseService {
  /**
   * Constructor gets client data service and toast manager.
   */
  constructor(
    private appClientDataService: AppClientDataService,
    private toastService: AppToastManagerService
  ) {
    super();
  }

  public async getUsersList(
    filters: IUsersListFilters
  ): Promise<PaginationResultsDto<UserBasicResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<UserBasicResponseDto>
    >(
      this.GET_ROUTES.USER_LIST,
      {
        queryParams: {
          ...(filters.tableFilters.itemsPerPage
            ? { itemsPerPage: filters.tableFilters.itemsPerPage }
            : {}),
          ...(filters.tableFilters.page
            ? { page: filters.tableFilters.page }
            : {}),
          ...(filters.searchInput
            ? {
                firstName: filters.searchInput,
                lastName: filters.searchInput,
                email: filters.searchInput,
                instructorCode: filters.searchInput
              }
            : {}),
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.schoolGroupId
            ? { schoolGroupId: filters.schoolGroupId }
            : {}),
          active: !filters.inactive
        }
      },
      UserBasicResponseDto
    );
  }

  public getUsersListDefaultFilters(): IUsersListFilters {
    const currentUser = this.StorageManager.getLoggedInUser();
    const defaultFilters: IUsersListFilters = {
      searchInput: null,
      inactive: false,
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.USERS_LIST
      )
    };
    if (currentUser.schoolGroupId)
      defaultFilters.schoolGroupId = currentUser.schoolGroupId;
    if (currentUser.currentSchoolId)
      defaultFilters.schoolId = currentUser.currentSchoolId;
    return defaultFilters;
  }

  public async activateGuardians(): Promise<void> {
    this.toastService.info('Activating guardians...');
    try {
      await this.appClientDataService.execute(
        this.GET_ROUTES.ACTIVATE_GUARDIANS,
        {
          method: this.METHOD.GET
        }
      );
      this.toastService.success(
        'Guardians are being activated, this will take a little while.'
      );
    } catch (err) {
      this.toastService.error('Error activating guardians!');
      logger.error(err);
    }
  }

  public async getUsersListPageResolverData(): Promise<IUsersListResolverData> {
    try {
      const defaultFilters = this.getUsersListDefaultFilters();
      return {
        defaultFilters,
        users: await this.getUsersList(defaultFilters)
      };
    } catch (err) {
      logger.error(err.message);
    }
  }

  public async getUserById(userId: number): Promise<UserDetailResponseDto> {
    return this.appClientDataService.execute<UserDetailResponseDto>(
      this.GET_ROUTES.USER_BY_ID,
      {
        pathParams: {
          id: userId
        }
      },
      UserDetailResponseDto
    );
  }

  public async getUser(): Promise<UserDetailResponseDto> {
    const userInfo: LoginResponseDto = this.StorageManager.getLoggedInUser();
    if (userInfo && userInfo.id) {
      return this.appClientDataService.execute<UserDetailResponseDto>(
        this.GET_ROUTES.USER_SELF,
        {
          pathParams: {
            id: userInfo.id
          }
        },
        UserDetailResponseDto
      );
    } else {
      this.toastService.warn('No district found! Are you logged in?');
      return null;
    }
  }

  public async getRoleOptions(userId: number): Promise<RoleResponseDto[]> {
    return this.appClientDataService.execute<RoleResponseDto[]>(
      this.GET_ROUTES.USER_AVAILABLE_ROLES,
      {
        pathParams: {
          id: userId
        }
      },
      RoleResponseDto
    );
  }

  public async createUser(user: UserCreateDto): Promise<UserDetailResponseDto> {
    return this.appClientDataService.execute<UserDetailResponseDto>(
      this.POST_ROUTES.CREATE_USER,
      {
        body: user,
        method: this.METHOD.POST
      },
      UserDetailResponseDto
    );
  }

  public async updateUser(
    user: UserUpdateDto,
    id: number
  ): Promise<UserDetailResponseDto> {
    return this.appClientDataService.execute<UserDetailResponseDto>(
      this.PUT_ROUTES.UPDATE_USER,
      {
        method: this.METHOD.PUT,
        pathParams: {
          id
        },
        body: user
      },
      UserDetailResponseDto
    );
  }

  public async clearSchoolSettings(userId: number): Promise<LoginResponseDto> {
    return this.appClientDataService.execute<LoginResponseDto>(
      this.PATCH_ROUTES.CLEAR_SCHOOL_SETTINGS,
      {
        method: this.METHOD.PATCH,
        pathParams: {
          id: userId
        }
      },
      LoginResponseDto
    );
  }

  public async deactivateUser(id: number): Promise<UserDetailResponseDto> {
    return this.appClientDataService.execute<UserDetailResponseDto>(
      this.DELETE_ROUTES.DEACTIVATE_USER,
      {
        method: this.METHOD.DELETE,
        pathParams: {
          id
        }
      }
    );
  }

  public async reactivateUser(id: number): Promise<UserDetailResponseDto> {
    return this.appClientDataService.execute<UserDetailResponseDto>(
      this.PUT_ROUTES.REACTIVATE_USER,
      {
        method: this.METHOD.PUT,
        pathParams: {
          id
        }
      }
    );
  }

  /**
   * Get all the privileges for a specific user
   */
  public async getPrivilegesForUserById(userId: string): Promise<IPrivilege[]> {
    return this.appClientDataService.execute(this.GET_ROUTES.USERS_PRIVILEGES, {
      pathParams: {
        id: userId
      }
    });
  }

  /**
   * Updates the user's password.
   * @param userId The USER ID of the user want to update.
   * @param password The new plain text password.
   */
  public async updatePassword(
    userId: number,
    password: string
  ): Promise<IUser> {
    if (!userId) {
      const user: LoginResponseDto = this.StorageManager.getLoggedInUser();
      if (user) {
        userId = user.id;
      } else {
        throw new Error('Are you logged in?');
      }
    }

    return this.appClientDataService.execute(
      this.PATCH_ROUTES.PASSWORD_BY_USER_ID,
      {
        method: this.METHOD.PUT,
        pathParams: {
          id: userId
        },
        body: {
          password
        }
      }
    );
  }
}
