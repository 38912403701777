"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobType = void 0;
var JobType;
(function (JobType) {
    JobType["BULK_STUDENT_PROFILE_PHOTO_UPLOAD"] = "BULK_STUDENT_PROFILE_PHOTO_UPLOAD";
    JobType["DOWNLOADABLE_REPORTS"] = "DOWNLOADABLE_REPORTS";
    JobType["BEHAVIOR_CODE_ENTRY_UPLOAD"] = "BEHAVIOR_CODE_ENTRY_UPLOAD";
    JobType["ACTIVATE_GUARDIANS"] = "ACTIVATE_GUARDIANS";
})(JobType || (exports.JobType = JobType = {}));
