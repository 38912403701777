"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchoolDetailResponseDto = void 0;
const school_create_dto_1 = require("./school-create.dto");
const class_transformer_1 = require("class-transformer");
const transform_config_1 = require("../../transform-config");
class SchoolDetailResponseDto extends school_create_dto_1.SchoolCreateDto {
    constructor(options) {
        super(options);
    }
    getFields() {
        // response objects should include the id field in mapping
        return super
            .getFields()
            .concat(['id', 'schoolGroupId'])
            .filter((field) => field !== 'activeTerm' && field !== 'settings');
    }
    mapFields(source) {
        if (source.schoolDetail)
            super.mapFields(source.schoolDetail, true, true);
        super.mapFields(source);
        return this;
    }
    getTransforms() {
        return [
            {
                destination: 'activeTerms',
                transform: transform_config_1.TransformConfig.transformArray('terms', (source) => {
                    return source
                        .filter((term) => term.active)
                        .map((term) => {
                        return term.id;
                    });
                })
            },
            {
                destination: 'settings',
                transform: transform_config_1.TransformConfig.transformSchoolSettings()
            }
        ];
    }
    id;
    schoolGroupId;
}
exports.SchoolDetailResponseDto = SchoolDetailResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], SchoolDetailResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], SchoolDetailResponseDto.prototype, "schoolGroupId", void 0);
