import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { ActivatedRoute } from '@angular/router';
import { AppIncidentService } from '../incident.service';
import {
  DefaultOptionCategoriesIncidentDisplayValue,
  DefaultOptionCategoriesIncidentEnum,
  IncidentOptionsResponseDto,
  IOptionResponse,
  OptionsUpdateDto
} from '@whetstoneeducation/hero-common';
import { MatDialog } from '@angular/material/dialog';
import { AppOptionCreateEditModalComponent } from './option-create-edit-modal/option-create-edit-modal.component';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-incident-config',
  templateUrl: './incident-config.component.html',
  styleUrls: ['./incident-config.component.scss']
})
export class AppIncidentConfigComponent
  extends BaseComponent
  implements OnInit
{
  public optionsDict: IncidentOptionsResponseDto;

  public selectedCategory: DefaultOptionCategoriesIncidentEnum;
  constructor(
    public route: ActivatedRoute,
    private dialog: MatDialog,
    public incidentService: AppIncidentService,
    public toastService: AppToastManagerService
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  public async loadData() {
    this.isLoading = true;
    this.optionsDict = this.route.snapshot.data
      .data as IncidentOptionsResponseDto;
    this.isLoading = false;
  }

  public async reloadData() {
    this.optionsDict = await this.incidentService.getIncidentConfig();
  }

  public onCategoryChange(category: DefaultOptionCategoriesIncidentEnum) {
    this.selectedCategory = category;
  }

  public editOption(option: IOptionResponse) {
    this.dialog.open(AppOptionCreateEditModalComponent, {
      data: {
        option,
        onClose: async () => {
          await this.reloadData();
        }
      },
      width: '500px',
      height: '400px'
    });
  }

  public async deleteOption(option: IOptionResponse) {
    this.dialog.open(AppConfirmationDialogComponent, {
      data: {
        title: 'Delete Option',
        content: `Are you sure you want to deactivate option ${option.name}?`,
        actions: [
          {
            key: 'cancel',
            label: 'Cancel',
            color: 'primary',
            classes: 'modal-delete-button'
          },
          {
            key: 'delete',
            label: 'Yes',
            color: 'primary',
            classes: 'modal-submit-button',
            awaitAction: true,
            action: async () => {
              await this.incidentService.updateOption(
                option.id,
                new OptionsUpdateDto({
                  active: false
                })
              );
              await this.reloadData();
              this.toastService.success('Option deleted');
            }
          }
        ]
      }
    });
  }

  public async addOption() {
    const categoryId = this.optionsDict[this.selectedCategory]?.id;
    if (!categoryId) {
      this.toastService.error('Error: Please Contact Support');
      return;
    }
    this.dialog.open(AppOptionCreateEditModalComponent, {
      width: '500px',
      height: '400px',
      data: {
        categoryId,
        onClose: async () => {
          await this.reloadData();
        }
      }
    });
  }

  protected readonly DefaultOptionCategoriesIncidentEnum =
    DefaultOptionCategoriesIncidentEnum;
  protected readonly DefaultOptionCategoriesIncidentDisplayValue =
    DefaultOptionCategoriesIncidentDisplayValue;
}
