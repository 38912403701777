import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { IIncidentListResolverData } from './incident-list-models/incident-list-resolver.interface';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppIncidentService } from '../incident.service';

@Injectable({ providedIn: 'root' })
export class AppIncidentListResolver extends BaseResolver<IIncidentListResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public incidentService: AppIncidentService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve() {
    return this.incidentService.getIncidentListResolverData();
  }
}
