"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingDict = void 0;
const enums_1 = require("../enums");
exports.SettingDict = {
    [enums_1.SettingsName.TIMEZONE]: {
        setting: 'timezone',
        value: null,
        groupLevel: true,
        schoolLevel: true
    },
    [enums_1.SettingsName.COURSE_SCHEDULE_IMPORT_SEQUENCE]: {
        setting: 'course_schedule_import_sequence',
        value: 1,
        groupLevel: false,
        schoolLevel: true
    },
    [enums_1.SettingsName.STUDENT_IMPORT_SEQUENCE]: {
        setting: 'student_import_sequence',
        value: 1,
        groupLevel: false,
        schoolLevel: true
    },
    [enums_1.SettingsName.INCIDENT]: {
        setting: 'incident',
        value: '0',
        groupLevel: false,
        schoolLevel: true
    },
    [enums_1.SettingsName.EMAIL_GUARDIAN_INVITE]: {
        setting: 'email_guardian_invite',
        value: '0',
        groupLevel: false,
        schoolLevel: true
    },
    [enums_1.SettingsName.GUARDIANS_MASS_ACTIVATED]: {
        setting: 'guardians_mass_activated',
        value: '1970-01-01T00:00:00.000Z',
        groupLevel: false,
        schoolLevel: true
    }
};
