import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
  selector: 'app-utc-time-input',
  templateUrl: './utc-time-input.template.html',
  styleUrls: ['./utc-time-input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppUtcTimeInputComponent),
      multi: true
    }
  ]
})
export class AppUtcTimeInputComponent implements ControlValueAccessor, OnInit {
  @Input() placeholder = 'Enter time';
  @Input() displayTimezone = 'America/Chicago'; // Default to CST/CDT
  @Input() date: Date = new Date(); // Default date, can be overridden
  @Input() hideUtc: boolean;
  utcValue: string = ''; // The value in UTC
  displayValue: string = ''; // The value in the display timezone

  onChange = (value: any) => {};
  onTouched = () => {};

  ngOnInit(): void {
    this.updateDisplayValue();
  }

  // Method to handle user input changes
  onInputChange(event: any): void {
    this.displayValue = event.target.value;
    this.updateUtcValueFromDisplay();
    this.onChange(this.utcValue);
  }

  // Update the display value based on the timezone input
  updateDisplayValue(): void {
    if (this.utcValue && this.date) {
      const formattedDate = dayjs(this.date).format('YYYY-MM-DD');
      // Combine date and time to create a full UTC datetime object
      const utcDateTime = dayjs.utc(`${formattedDate}T${this.utcValue}`);

      // Convert the full UTC datetime to the desired timezone (accounting for DST)
      this.displayValue = utcDateTime.tz(this.displayTimezone).format('HH:mm');
    }
  }

  // Update the UTC value from the display input value
  updateUtcValueFromDisplay(): void {
    if (this.displayValue && this.date) {
      const formattedDate = dayjs(this.date).format('YYYY-MM-DD');
      // Assume the input time is in the specified timezone, then convert to UTC
      const localDateTime = dayjs.tz(
        `${formattedDate}T${this.displayValue}`,
        this.displayTimezone
      );

      // Convert the local timezone time to UTC
      this.utcValue = localDateTime.utc().format('HH:mm:ss');
    }
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    if (value) {
      this.utcValue = value;
      this.updateDisplayValue();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Optionally handle disabled state
  }
}
