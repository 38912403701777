"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudentProfilePhotoBulkUploadTemplateVariablesDto = void 0;
const notification_templates_1 = require("../notification-templates");
class StudentProfilePhotoBulkUploadTemplateVariablesDto extends notification_templates_1.BaseTemplateVariablesDto {
    constructor(bulkUploadTemplateVariablesOptions) {
        super();
        Object.assign(this, bulkUploadTemplateVariablesOptions);
    }
    mapFields(source, skipNested, skipTransforms) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    getFields() {
        return [
            ...super.getFields(),
            'imagesAdded',
            'imagesFound',
            'filesFound',
            'invalidFilesFormat',
            'studentsNotFound',
            'readErrors',
            'failedCounter'
        ];
    }
    imagesAdded;
    imagesFound;
    filesFound;
    invalidFilesFormat;
    studentsNotFound;
    readErrors;
    failedCounter;
}
exports.StudentProfilePhotoBulkUploadTemplateVariablesDto = StudentProfilePhotoBulkUploadTemplateVariablesDto;
