import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AppStudentsService } from '../../../students/students.service';
import { AppCustomRostersService } from '../../custom-rosters.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CustomRostersResponseDto,
  CustomRostersUpdateDto,
  DateUtil,
  StudentPerformanceDto
} from '@whetstoneeducation/hero-common';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { AddStudentColumnsEnum } from '../../custom-roster-models/add-student-columns.enum';
import { IStudentsListFilters } from '../../../students/students-list-models/students-list-filters.interface';
import { BaseStudentListComponent } from '../../../../shared/base-student-list/base-student-list.component';

@Component({
  selector: 'app-add-students-modal',
  templateUrl: './add-students-modal.component.html',
  styleUrls: ['./add-students-modal.component.scss']
})
export class AddStudentsModalComponent
  extends BaseStudentListComponent
  implements OnInit, OnDestroy
{
  public customRoster: CustomRostersResponseDto;
  public search: string;
  public grade: string;
  public filters: IStudentsListFilters;
  studentsAdded: number[] = [];
  students: StudentPerformanceDto[] = [];
  constructor(
    public customRosterService: AppCustomRostersService,
    public studentsService: AppStudentsService,
    public dialogRef: MatDialogRef<AddStudentsModalComponent>,
    public toastService: AppToastManagerService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      customRoster: CustomRostersResponseDto;
      onClose: () => Promise<void>;
    }
  ) {
    super(studentsService, toastService);
  }

  async ngOnDestroy() {
    await this.data.onClose();
  }

  public get displayedColumns() {
    return Object.values(AddStudentColumnsEnum);
  }

  public columnValues = {
    [AddStudentColumnsEnum.STUDENT_NAME]: ['Student Name'],
    [AddStudentColumnsEnum.STUDENT_ID]: ['Student ID'],
    [AddStudentColumnsEnum.GRADE]: ['Grade'],
    [AddStudentColumnsEnum.ACTION]: ['Add']
  };

  async ngOnInit() {
    this.isLoading = true;
    this.customRoster = this.data.customRoster;
    const currentSchoolId = this.StorageManager.getCurrentSchoolId();
    if (!currentSchoolId) {
      this.toastService.error('Please select a school first');
      this.dialogRef.close();
      this.isLoading = false;

      return;
    }

    await this.loadFiltersAndStudents({ customRosterId: this.customRoster.id });
  }

  async addStudent(studentId: number) {
    this.isLoading = true;
    try {
      await this.customRosterService.updateCustomRoster(
        this.data.customRoster.id,
        new CustomRostersUpdateDto({ studentId })
      );
      this.studentsAdded.push(studentId);
      await this.updateStudentsList(this.filters);
    } catch (error) {
      this.toastService.error('Failed to add student');
    }
    this.isLoading = false;
  }

  async handleClose() {
    await this.data.onClose();
    this.dialogRef.close();
  }

  protected readonly columns = AddStudentColumnsEnum;
  protected readonly DateUtil = DateUtil;
}
