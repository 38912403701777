export const postRoutes = {
  LOGIN: '/auth/login',
  CREATE_SCHOOL_GROUP: '/school-groups',
  CREATE_SCHOOL: '/schools',
  CREATE_USER: '/users',
  CREATE_STUDENT_GUARDIAN: '/students/:id/create-guardian',
  CREATE_ROLE: '/roles',
  CREATE_BEHAVIOR_CODE: '/behavior-codes',
  CREATE_BEHAVIOR_CODE_ENTRY: '/behavior-code-entries',
  BATCH_CREATE_BEHAVIOR_CODE_ENTRIES: '/behavior-code-entries/batch',
  UPLOAD_BEHAVIOR_CODE_ENTRIES:
    '/behavior-code-entries/upload-behavior-entries',
  CREATE_BEHAVIOR_CODE_REACTION: '/behavior-code-reactions',
  CREATE_BEHAVIOR_CODE_INTERVENTION: '/behavior-code-interventions',
  CREATE_BEHAVIOR_CODE_NOTIFICATION: '/behavior-code-notifications',
  CREATE_BELL_SCHEDULE: '/bell-schedules',
  CREATE_BELL_SCHEDULE_PERIOD: '/bell-schedule-periods',
  CREATE_BELL_SCHEDULE_PERIOD_TIME: '/bell-schedule-period-times',
  CREATE_CALENDAR_DAY: '/calendar-days',
  CREATE_INTERVENTION: '/interventions',
  CREATE_REACTION: '/reactions',
  CREATE_STUDENT: '/students',
  CREATE_STUDENT_GROUP: '/student-groups',
  CREATE_SUBSCRIPTION: '/subscriptions',
  SET_UP_YEAR: '/calendar-days/set-up-year',
  CREATE_SFTP_ACCOUNT: '/sftp/accounts',
  CREATE_SFTP_SETTINGS: '/sftp/settings',
  UPDATE_SFTP_SETTINGS: '/sftp/settings/:id',
  RESET_SFTP_ACCOUNT_PASSWORD: '/sftp/accounts/:id/reset-password',
  UPLOAD_FILE_IMPORT: '/import-file/upload',
  CREATE_NOTIFICATION_TEMPLATE: '/notification/templates',
  CREATE_NOTIFICATION_SETTINGS: '/notification/settings',
  PRINTER_CONFIGURATION: '/printer-configuration',
  CREATE_STUDENT_GROUP_BELL_SCHEDULE_CALENDAR_DAYS:
    '/student-group-bell-schedule-calendar-days/batch',
  CREATE_EXPORT_SETTINGS: '/export/settings',
  UPDATE_EXPORT_SETTINGS: '/export/settings/:id',
  UPLOAD_STUDENT_PHOTO: '/students/:id/profile-photo',
  BULK_UPLOAD_STUDENT_PHOTO: '/students/upload-profile-photos',
  CUSTOM_ROSTER: '/custom-rosters',
  CREATE_OPTION: '/options',
  CREATE_INCIDENT: '/incidents'
};
export type PostRoutes = typeof postRoutes;
