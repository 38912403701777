<div class="table-container scroll-table mat-elevation-z2">
  <div class="mat-table">
    <mat-table
      class="behavior-codes-notifications-table mat-table"
      [dataSource]="dataSource"
      [ngStyle]="{'min-width': '0px'}"
      matSort
      matSortDisableClear>
      <!-- NAME COLUMN -->
      <ng-container matColumnDef="{{columns.NAME}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.NAME}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.notification?.name }}
        </mat-cell>
      </ng-container>

      <!-- DESCRIPTION COLUMN -->
      <ng-container matColumnDef="{{columns.DESCRIPTION}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.DESCRIPTION}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.description }} </mat-cell>
      </ng-container>

      <!-- TRIGGER AT COLUMN -->
      <ng-container matColumnDef="{{columns.TRIGGER_AT}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.TRIGGER_AT}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row["triggerCount"] !== 0 ? row["triggerCount"] : "default" }}
        </mat-cell>
      </ng-container>

      <!-- ACTIONS COLUMN -->
      <ng-container matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button
            mat-flat-button
            class="action-button icon-button"
            [routerLink]="'/behavior-codes/' + row.behaviorCode?.id?.toString() + '/behavior-code-notifications/' + row.id.toString()">
            <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
          </button>
          <button
            mat-flat-button
            class="action-button icon-button"
            (click)="deleteNotificationFromBehavior(row)">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      <!-- Loading Bar Rows -->
      <ng-container matColumnDef="Loading">
        <mat-header-cell *matHeaderCellDef>
          <mat-progress-bar
            *ngIf="isLoading"
            color="primary"
            mode="indeterminate"></mat-progress-bar>
        </mat-header-cell>
        <mat-footer-cell *matFooterCellDef>
          <mat-progress-bar
            *ngIf="isLoading"
            color="primary"
            mode="indeterminate"></mat-progress-bar>
        </mat-footer-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="isLoading ? ['Loading'] : []"
        class="loading-header"
        [class.hidden-row]="!isLoading"></mat-header-row>
      <mat-footer-row
        *matFooterRowDef="isLoading ? ['Loading'] : []"
        class="loading-footer"
        [class.hidden-row]="!isLoading"></mat-footer-row>

      <ng-container matColumnDef="noData">
        <mat-footer-cell *matFooterCellDef>
          No notifications found
        </mat-footer-cell>
      </ng-container>
      <mat-footer-row
        *matFooterRowDef="dataArr?.length || isLoading ? [] : ['noData']"
        class="empty-footer"
        [class.hidden-row]="dataArr?.length || isLoading"></mat-footer-row>
    </mat-table>
  </div>
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-bottom">
    <app-pagination
      *ngIf="showPagination"
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
</div>
