<div class="twoColumnLayout">
  <div class="column">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title> INCIDENT INFORMATION </mat-card-title>
      </mat-card-header>
    </mat-card>
    <mat-card-content class="mat-elevation-z3">
      <div class="form-row">
        <form [formGroup]="dateTimeForm" class="date-time-form">
          <div class="half-width-input">
            <mat-form-field class="full-width-input">
              <mat-label>Date</mat-label>
              <input
                formControlName="date"
                matInput
                [matDatepicker]="datePicker"
                type="text" />
              <mat-datepicker-toggle
                matSuffix
                [for]="datePicker"></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
              <app-error-display
                [form]="dateTimeForm"
                controlName="date"></app-error-display>
            </mat-form-field>
          </div>
          <div class="half-width-input">
            <mat-form-field class="full-width-input">
              <mat-label>Time</mat-label>
              <input formControlName="time" matInput type="time" />
              <app-error-display
                [form]="dateTimeForm"
                controlName="time"></app-error-display>
            </mat-form-field>
          </div>
        </form>
      </div>
      <form [formGroup]="incidentForm">
        <mat-form-field class="full-width-input">
          <mat-label>Incident Type</mat-label>
          <mat-select formControlName="incidentTypeId">
            <mat-option
              *ngFor="let incidentType of incidentTypeOptions"
              [value]="incidentType.value">
              {{ incidentType.display }}
            </mat-option>
          </mat-select>
          <app-error-display
            [form]="incidentForm"
            controlName="incidentTypeId"></app-error-display>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <mat-label>Incident Place</mat-label>
          <mat-select formControlName="incidentPlaceId">
            <mat-option
              *ngFor="let incidentPlace of incidentPlaceOptions"
              [value]="incidentPlace.value">
              {{ incidentPlace.display }}
            </mat-option>
          </mat-select>
          <app-error-display
            [form]="incidentForm"
            controlName="incidentPlaceId"></app-error-display>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <mat-label> Description</mat-label>
          <textarea formControlName="description" matInput rows="4"></textarea>
          <app-error-display
            [form]="incidentForm"
            controlName="description"></app-error-display>
        </mat-form-field>
      </form>
    </mat-card-content>
  </div>
  <div class="column">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title> PEOPLE INVOLVED </mat-card-title>
      </mat-card-header>
    </mat-card>
    <mat-card-content class="mat-elevation-z3">
      <div class="add-student-button-container">
        <button
          mat-raised-button
          color="primary"
          (click)="openAddParticipantModal()">
          + STUDENTS
        </button>
      </div>
      <ul class="participant-list">
        <li
          class="participant-list-item"
          *ngFor="let participant of participants">
          <span class="participant-icon">
            <app-student-profile-photo
              [selectedImage]="participant.profilePhotoUrl"
              [studentId]="participant.id"
              [hideProfileImageSelector]="true"
              [imageWidth]="30"
              [imageHeight]="30">
            </app-student-profile-photo>

            {{ participant.firstName }} {{ participant.lastName }}
          </span>
          <span class="participant-id">
            ID: {{ participant.studentExtId }}</span
          >
          <span class="participant-grade"> Grade: {{ participant.grade }}</span>
          <div [class]="'role-' + participant.type">
            {{ participant.type }}
          </div>
          <button
            mat-flat-button
            class="action-button icon-button"
            (click)="removeParticipant(participant.id)">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </button>
        </li>
      </ul>
    </mat-card-content>
  </div>
</div>
