"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentStatusEnum = void 0;
var IncidentStatusEnum;
(function (IncidentStatusEnum) {
    IncidentStatusEnum["DRAFT"] = "Draft";
    IncidentStatusEnum["PENDING"] = "Pending";
    IncidentStatusEnum["FINALIZED"] = "Finalized";
    IncidentStatusEnum["REJECTED"] = "Rejected";
})(IncidentStatusEnum || (exports.IncidentStatusEnum = IncidentStatusEnum = {}));
